import Keycloak, { KeycloakInitOptions } from "keycloak-js";

declare module '#app' {
    interface NuxtApp {
        $keycloak: Keycloak
        $keycloakInitialization: (mustBeLoggedIn?: boolean) => Promise<void>
    }
}
declare module 'vue' {
    interface ComponentCustomProperties {
        $keycloak: Keycloak
        $keycloakInitialization: (mustBeLoggedIn?: boolean) => Promise<void>
    }
}

export default defineNuxtPlugin(async nuxtApp => {
    let config = {
        realm: "global",
        clientId: "pleasetalk",
        url: "https://auth.mega.pp.ua/",
    };

    const keycloak = new Keycloak(config);

    let initConfig: KeycloakInitOptions = {
        timeSkew: 10, // 10 seconds should be plenty to check for token expiry.
        onLoad: 'login-required',
        responseMode: "fragment",
        pkceMethod: 'S256',
        // Unfortunately without this Firefox will break and will be
        // reloading on iframe check.
        // https://issues.redhat.com/browse/KEYCLOAK-12125
        checkLoginIframe: false,
    }

    const sessionAuthString = sessionStorage.getItem('auth')
    let sessionAuth = null
    if (sessionAuthString !== null) {
        sessionAuth = JSON.parse(sessionAuthString)
    }

    if (sessionAuth !== null) {
        initConfig.token = sessionAuth.token
        initConfig.idToken = sessionAuth.idToken
        initConfig.refreshToken = sessionAuth.refreshToken
    }

    keycloak.onAuthSuccess = () => {
        sessionStorage.setItem('auth', JSON.stringify({
            token: keycloak.token,
            idToken: keycloak.idToken,
            refreshToken: keycloak.refreshToken
        }))
    };

    keycloak.onAuthRefreshSuccess = () => {
        sessionStorage.setItem('auth', JSON.stringify({
            token: keycloak.token,
            idToken: keycloak.idToken,
            refreshToken: keycloak.refreshToken
        }))
    };

    keycloak.onAuthRefreshError = () => {
        sessionStorage.removeItem('auth');
    };

    keycloak.onTokenExpired = () => {
        keycloak.updateToken(120);
    };

    keycloak.onAuthLogout = () => {
        sessionStorage.removeItem('auth');
    }

    let initialized = false;

    const keycloakInitialization = (mustBeLoggedIn: boolean = true): Promise<void> => {
        if (initialized) {
            return (async () => {})()
        }

        initConfig.onLoad = mustBeLoggedIn ? 'login-required' : 'check-sso';

        return keycloak
            .init(initConfig)
            .then((authenticated) => {
                initialized = true;

                if (!authenticated) {
                    return
                }

                if (keycloak.refreshToken !== undefined && keycloak.isTokenExpired(60)) {
                    keycloak.updateToken(120).catch(function () {
                        console.log('Failed to refresh the token, or the session has expired');
                    });
                }
            });
    }

    return {
        provide: {
            keycloakInitialization,
            keycloak,
        }
    }
})
