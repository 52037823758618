export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"PleaseTalk allows to find people just like you, that you can to talk to, become friends with. It also provides an way for you to find friend as well! All while being anonymous."},{"charset":"utf-8"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"title":"PleaseTalk - talk, find friends, anonymously.","htmlAttrs":{"lang":"en-US"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'