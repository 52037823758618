import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css';
import '~/styles/main.css'

export default defineNuxtPlugin(nuxtApp => {
    const vuetify = createVuetify({
        ssr: true,
        // your config will come here
        // components: {
        //     VVirtualScroll,
        // }
    })

    nuxtApp.vueApp.use(vuetify)
})