import { default as chatcdcDIyUuf8Meta } from "/opt/buildhome/repo/pages/chat.vue?macro=true";
import { default as _91_46_46_46slug_93lnRvSSjMOFMeta } from "/opt/buildhome/repo/pages/docs/[...slug].vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as support_45usIBRKE3QBwGMeta } from "/opt/buildhome/repo/pages/support-us.vue?macro=true";
export default [
  {
    name: chatcdcDIyUuf8Meta?.name ?? "chat",
    path: chatcdcDIyUuf8Meta?.path ?? "/chat",
    meta: chatcdcDIyUuf8Meta || {},
    alias: chatcdcDIyUuf8Meta?.alias || [],
    redirect: chatcdcDIyUuf8Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93lnRvSSjMOFMeta?.name ?? "docs-slug",
    path: _91_46_46_46slug_93lnRvSSjMOFMeta?.path ?? "/docs/:slug(.*)*",
    meta: _91_46_46_46slug_93lnRvSSjMOFMeta || {},
    alias: _91_46_46_46slug_93lnRvSSjMOFMeta?.alias || [],
    redirect: _91_46_46_46slug_93lnRvSSjMOFMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileg2OzvUzQjKMeta?.name ?? "profile",
    path: profileg2OzvUzQjKMeta?.path ?? "/profile",
    meta: profileg2OzvUzQjKMeta || {},
    alias: profileg2OzvUzQjKMeta?.alias || [],
    redirect: profileg2OzvUzQjKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: support_45usIBRKE3QBwGMeta?.name ?? "support-us",
    path: support_45usIBRKE3QBwGMeta?.path ?? "/support-us",
    meta: support_45usIBRKE3QBwGMeta || {},
    alias: support_45usIBRKE3QBwGMeta?.alias || [],
    redirect: support_45usIBRKE3QBwGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/support-us.vue").then(m => m.default || m)
  }
]